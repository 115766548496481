import { authorizePostRequest } from "api";
import { INotificationInput, ISmsInput } from "models/notification";

export const sendFcmNotificationApi = (data: INotificationInput) => {
  return authorizePostRequest(`notifications/fcm/send`, data);
};

export const sendSmsNotificationApi = (data: ISmsInput) => {
  return authorizePostRequest(`notifications/sms/send`, data);
};
