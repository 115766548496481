import { Navigate, createBrowserRouter } from "react-router-dom";
import SignIn from "pages/SignIn/SignIn";
import Forget from "pages/Forget/Forget";
import Reset from "pages/Reset/Reset";

export enum RouteName {
  SignIn = "/sign-in",
  ForgetPassword = "/forget-password",
  RestPassword = "/reset-password/:token",
  DashBoard = "/dashboard",
  Users = "/users",
  Accounts = "/accounts",
  Country = "/countries",
  Businesses = "/businesses",
  Debt = "/debts",
  Inventory = "/inventories",
  Person = "/people",
  Transaction = "/transactions",
  Setting = "/setting",
  Survey = "/surveys",
  CreateSurvey = "/surveys/create",
  UserDetail = "/users/:id",
  UserBusiness = "/users/:id/businesses",
  UserCashIn = "/users/:id/cash-ins",
  UserCashOut = "/users/:id/cash-outs",
  UserDebt = "/users/:id/debts",
  UserInventory = "/users/:id/inventories",
  UserCustomer = "/users/:id/customers",
  UserSuppliers = "/users/:id/suppliers",
}

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to={RouteName.SignIn} />,
  },
  {
    path: RouteName.SignIn,
    element: <SignIn />,
  },
  {
    path: RouteName.ForgetPassword,
    element: <Forget />,
  },
  {
    path: RouteName.RestPassword,
    element: <Reset />,
  },
]);
