import LabeledDateInput from "components/Form/LabeledDateInput";
import LabeledTextareaInput from "components/Form/LabeledTextareaInput";
import LabeledTextInput from "components/Form/LabeledTextInput";
import { Control } from "react-hook-form";

interface IProp {
  control: Control<any, any>;
}

export default function SurveyInfo({ control }: IProp) {
  return (
    <div className="w-full flex flex-col gap-y-2">
      <LabeledTextInput
        control={control}
        name="name"
        placeholder="Survey name"
        label="Survey Name"
      />
      <LabeledTextareaInput
        control={control}
        name="description"
        placeholder="Survey description"
        label="Survey Description"
      />
      <div className="w-full flex flex-col md:flex-row gap-x-8 gap-y-2">
        <LabeledDateInput
          control={control}
          name="started_at"
          placeholder="Started at"
          label="Started At"
        />
        <LabeledDateInput
          control={control}
          name="ended_at"
          placeholder="Ended at"
          label="Ended At"
        />
      </div>
    </div>
  );
}
