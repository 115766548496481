export const businessStates = [
  "Addis Ababa",
  "Afar",
  "Amhara",
  "Benishangul Gumuz",
  "Dire Dawa",
  "Gambella",
  "Harari",
  "Nationalities and Peoples Region (SNNPR)",
  "Oromia",
  "Somali",
  "Southern Nations",
  "Tigray",
];
