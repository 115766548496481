export const businessCategories = [
  "Electronic stores",
  "Pharmacy/Drug store",
  "Retail/Grocery Shop",
  "Supermarket",
  "POS Stand",
  "Fruit, Vegetable and Dairy",
  "Jewellery",
  "Perfumes",
  "Photo Studio",
  "Food Vendor/Restaurant",
  "Apparels (Clothing, Shows, etc)",
  "Repair Service",
  "Laundry Service",
  "Others",
  "Manufacturing",
  "Skincare Manufacturing",
];
