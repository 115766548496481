export function businessFoundedYears(): string[] {
  const data: string[] = [];

  let year = new Date().getFullYear();

  while (year > 1960) {
    data.push(year.toString());
    year--;
  }

  return data;
}
