import { Table, TableColumnsType } from "antd";
import SearchInput from "components/Form/SearchInput";
import PaginationComponent from "components/PaginationComponent";
import { tableColumns } from "helpers/table";
import useSurvey from "hooks/useSurvey";
import { ISurvey } from "models/survey";
import moment from "moment";
import { Link } from "react-router-dom";
import { RouteName } from "routes";

export default function Survey() {
  const {
    handlePageChange,
    handleRowClick,
    surveys,
    handleSearch,
    handleSortChange,
  } = useSurvey();

  return (
    <div className="w-full">
      <div className="w-full rounded-lg bg-white px-4 py-5 flex flex-col gap-y-4">
        <div className="w-full flex flex-col sm:flex-row sm:items-center justify-between gap-5">
          <h1 className="text-black text-2xl font-normal whitespace-nowrap">
            All Survey
          </h1>

          <SearchInput
            onChange={handleSearch}
            placeholder="Search surveys by first name, last name, phone"
          />

          <Link
            to={RouteName.CreateSurvey}
            className="hover:bg-btnColor transition-all duration-500 hover:text-white outline-none text-base font-semibold text-black whitespace-nowrap px-4 py-2 border-btnColor hover:border-dark-blue border-2 rounded-md w-full sm:w-fit"
          >
            Create Survey
          </Link>
        </div>
        <div className="w-full overflow-x-auto">
          <Table
            rowClassName="hover:cursor-pointer"
            columns={columns}
            onRow={handleRowClick}
            dataSource={surveys.data}
            loading={surveys.loading}
            pagination={{ position: ["none"] }}
            onChange={handleSortChange}
          />
        </div>
        <PaginationComponent
          loading={surveys.loading}
          page={surveys.page}
          perPage={surveys.per_page}
          total={surveys.total}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
}

const columns: TableColumnsType<ISurvey> = tableColumns<ISurvey>([
  {
    title: "Name",
    dataIndex: "name",
    sorter: true,
  },
  {
    title: "Description",
    dataIndex: "description",
    sorter: true,
  },
  {
    title: "Started At",
    dataIndex: "started_at",
    render: (startedAt: string) => <p>{moment(startedAt).format("LL")}</p>,
    sorter: true,
  },
  {
    title: "Ended At",
    dataIndex: "ended_at",
    render: (endedAt: string) => (
      <p>{endedAt ? moment(endedAt).format("LL") : "-"}</p>
    ),
    sorter: true,
  },

  {
    title: "Created At",
    dataIndex: "created_at",
    render: (createdAt: string) => <p>{moment(createdAt).format("LL")}</p>,
    sorter: true,
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (status: string) => (
      <div
        className={`capitalize flex items-center space-x-2 ${
          status === "open"
            ? "bg-green"
            : status === "suspended"
            ? "bg-yellow"
            : "bg-red"
        } text-white px-4 py-0.5 rounded-full`}
      >
        <span>{status}</span>
      </div>
    ),
    sorter: true,
  },
]);
