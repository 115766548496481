import { yupResolver } from "@hookform/resolvers/yup";
import { sendSmsNotificationApi } from "api/notification";
import { useNotificationContext } from "contexts/NotificationContext";
import { ISmsInput } from "models/notification";
import { useState } from "react";
import { useForm } from "react-hook-form";
import smsValidator from "validator/smsValidator";

export default function useSms() {
  const { showError, showSuccess } = useNotificationContext();
  const { control, handleSubmit } = useForm<ISmsInput>({
    resolver: yupResolver(smsValidator),
  });
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (input: ISmsInput) => {
    setLoading(true);
    const { error } = await sendSmsNotificationApi(input);
    console.log(error);
    if (error) showError(error.message);
    else showSuccess("Sms sent successfully");
    setLoading(false);
  };
  return {
    control,
    loading,
    handleSubmit,
    onSubmit,
  };
}
