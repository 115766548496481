import { Controller, Control } from "react-hook-form";

interface IProps {
  control: Control<any>;
  name: string;
  placeholder?: string;
}

export default function EmailInput({ control, name, placeholder }: IProps) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={""}
      rules={{ required: true }}
      render={({ field, fieldState: { error } }) => (
        <div className="w-full">
          <input
            {...field}
            type="email"
            className="w-full outline-none bg-white px-4 py-2 text-lg rounded-lg border "
            placeholder={placeholder}
          />
          <p
            className="text-sm font-normal text-left mt-1 capitalize"
            style={{ color: "red" }}
          >
            {error?.message}
          </p>
        </div>
      )}
    />
  );
}
