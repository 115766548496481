import { useState, useEffect, ChangeEvent } from "react";
import { useNotificationContext } from "contexts/NotificationContext";
import { appendKey } from "helpers/table";
import { useNavigate } from "react-router-dom";
import { RouteName } from "routes";
import { fetchSurveysApi } from "api/survey";
import { ISurvey, ISurveyData } from "models/survey";

export default function useSurvey() {
  const navigate = useNavigate();
  const { showError } = useNotificationContext();
  const [search, setSearch] = useState<string>("");
  const [surveys, setSurveys] = useState<ISurveyData>({
    data: [],
    loading: true,
    page: 1,
    per_page: 10,
    total: 0,
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [surveys.page, search]);

  const fetchData = async () => {
    setSurveys((prev) => ({ ...prev, loading: true }));
    const { data, error } = await fetchSurveysApi(
      surveys.page,
      surveys.per_page,
      search
    );
    if (data) {
      data.data = appendKey(data.data);
      setSurveys({ ...data, loading: false });
    } else showError(error!.message);
  };

  const handleSortChange = async (_: any, __: any, { field, order }: any) => {
    if (order) await fetchData();
  };

  const handlePageChange = (page: number) =>
    setSurveys((prev) => ({ ...prev, loading: true, page }));

  const handleRowClick = (survey: ISurvey) => ({
    onClick: () => navigate(`${RouteName.Survey}/${survey.id}`),
  });

  const handleSearch = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setSearch(value);
  };

  return {
    handlePageChange,
    handleRowClick,
    surveys,
    handleSearch,
    handleSortChange,
  };
}
