import axios from "axios";
import { IApiResponse, IError } from "models";

const BASE_URL = "https://api.melabooks.et/api/v2/";
// const BASE_URL = "https://mela-api-v2.onrender.com/api/v2/";

const defaultError: IError = {
  errors: ["Network error"],
  message: "Network error",
  status: 400,
};

const parseError = (error: any): IError => {
  if (error?.response?.data) {
    if (typeof error.response.data === "string")
      return {
        errors: [],
        message: error.response.data,
        status: error.response.status,
      };
    else if (typeof error.response.data === "object") {
      if (typeof error.response.data.detail === "string")
        return {
          errors: [],
          message: error.response.data.detail,
          status: error.response.status,
        };
      else if (Array.isArray(error.response.data.detail)) {
        const errors = error.response.data.detail.map((value: any) => {
          return `${value.loc[value.loc.length - 1]} ${value.msg}`;
        });
        return {
          errors: errors,
          message: errors.join("\n"),
          status: error.response.status,
        };
      } else return error.response.detail;
    } else return error.response.data;
  } else return defaultError;
};

export function getRequest<T>(url: string): Promise<IApiResponse<T>> {
  return new Promise((resolve) => {
    axios
      .get(`${BASE_URL}${url}`)
      .then((response) => resolve({ data: response.data }))
      .catch((error) => resolve({ error: parseError(error) }));
  });
}

export function postRequest<T>(
  url: string,
  data: any
): Promise<IApiResponse<T>> {
  return new Promise((resolve) => {
    axios
      .post(`${BASE_URL}${url}`, data)
      .then((response) => resolve({ data: response.data }))
      .catch((error) => {
        resolve({ error: parseError(error) });
      });
  });
}

export function authorizeGetRequest<T>(url: string): Promise<IApiResponse<T>> {
  return new Promise((resolve) => {
    axios
      .get(`${BASE_URL}${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => resolve({ data: response.data }))
      .catch((error) => resolve({ error: parseError(error) }));
  });
}

export function authorizePostRequest<T>(
  url: string,
  data: any
): Promise<IApiResponse<T>> {
  return new Promise((resolve) => {
    axios
      .post(`${BASE_URL}${url}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => resolve({ data: response.data }))
      .catch((error) => {
        resolve({ error: parseError(error) });
      });
  });
}

export function authorizePatchRequest<T>(
  url: string,
  data: any
): Promise<IApiResponse<T>> {
  return new Promise((resolve) => {
    axios
      .patch(`${BASE_URL}${url}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => resolve({ data: response.data }))
      .catch((error) => resolve({ error: parseError(error) }));
  });
}

export function authorizePutRequest<T>(
  url: string,
  data: any
): Promise<IApiResponse<T>> {
  return new Promise((resolve) => {
    axios
      .put(`${BASE_URL}${url}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => resolve({ data: response.data }))
      .catch((error) => resolve({ error: parseError(error) }));
  });
}

export function authorizeDeleteRequest<T>(
  url: string
): Promise<IApiResponse<T>> {
  return new Promise((resolve) => {
    axios
      .delete(`${BASE_URL}${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => resolve({ data: response.data }))
      .catch((error) => resolve({ error: parseError(error) }));
  });
}

export * from "./auth";
