import logo from "assets/image/Logo.png";
import { NavLink } from "react-router-dom";
import { RouteName } from "routes";
import { Button, Menu } from "antd";
import {
  DashboardOutlined,
  UserSwitchOutlined,
  TransactionOutlined,
  SettingOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { MdManageAccounts } from "react-icons/md";
import { FaUsers, FaGlobe } from "react-icons/fa6";
import { IoIosBusiness } from "react-icons/io";
import { FcDebt } from "react-icons/fc";
import { MdInventory } from "react-icons/md";

interface IProp {
  show: boolean;
  handleClose: () => void;
}

function Sidebar({ show, handleClose }: IProp) {
  const links = [
    {
      to: RouteName.DashBoard,
      name: "Dashboard",
      icon: <DashboardOutlined className="text-3xl" />,
    },
    {
      to: RouteName.Accounts,
      name: "Accounts",
      icon: <MdManageAccounts className="text-3xl" />,
    },
    {
      to: RouteName.Users,
      name: "Users",
      icon: <FaUsers className="text-3xl" />,
    },
    {
      to: RouteName.Country,
      name: "Countries",
      icon: <FaGlobe className="text-3xl" />,
    },
    {
      to: RouteName.Businesses,
      name: "Businesses",
      icon: <IoIosBusiness className="text-3xl" />,
    },
    {
      to: RouteName.Person,
      name: "Customers/Suppliers",
      icon: <UserSwitchOutlined className="text-3xl" />,
    },
    {
      to: RouteName.Debt,
      name: "Debts",
      icon: <FcDebt className="text-3xl" />,
    },
    {
      to: RouteName.Inventory,
      name: "Inventories",
      icon: <MdInventory className="text-3xl" />,
    },
    {
      to: RouteName.Transaction,
      name: "Transactions",
      icon: <TransactionOutlined className="text-3xl" />,
    },
    {
      to: RouteName.Setting,
      name: "Settings",
      icon: <SettingOutlined className="text-3xl" />,
    },
    {
      to: RouteName.Survey,
      name: "Surveys",
      icon: <SettingOutlined className="text-3xl" />,
    },
  ];

  return (
    <div
      style={{ transition: "width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s" }}
      className="bg-sideBarColor h-screen py-8 fixed z-20 left-0 top-0 ease-in-out duration-500 md:w-[200px]"
    >
      <div className="w-full flex justify-center items-center gap-x-2 px-3">
        {!show && (
          <>
            <img src={logo} alt="logo" className="h-6 w-6" />
            <p className="uppercase text-lg font-bold tracking-widest">
              Melabooks
            </p>
          </>
        )}
        <div className="grow" />
        <Button
          type="primary"
          className="bg-[#1c21ad] md:hidden flex items-center justify-center px-2.5 py-2.5"
          onClick={handleClose}
        >
          {show ? <ArrowRightOutlined /> : <ArrowLeftOutlined />}
        </Button>
      </div>
      <Menu
        mode="inline"
        items={links.map((value, key) => ({
          key,
          icon: value.icon,
          label: (
            <NavLink
              key={key}
              className={({ isActive }) =>
                `${
                  isActive
                    ? "before:absolute before:w-1 before:h-6 before:left-0  text-base font-semibold  text-dark-blue "
                    : "text-base text-left text-sideTextColor hover:scale-105  hover:font-semibold"
                } w-40 text-left`
              }
              to={value.to}
            >
              {value.name}
            </NavLink>
          ),
        }))}
        inlineCollapsed={show}
        className="flex flex-col items-center gap-y-2 bg-sideBarColor py-3"
      />
    </div>
    /* <div className="w-full flex justify-center items-center gap-x-2 px-3">
        <div className="grow" />
        {!show && (
          <>
            <img src={logo} alt="logo" className="h-6 w-6" />
            <p className="uppercase text-lg font-bold tracking-widest">
              Melabooks
            </p>
          </>
        )}
        <div className="grow" />
        <Button
          type="primary"
          className="bg-[#1c21ad] md:hidden"
          onClick={handleClose}
        >
          {show ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button> 
      <button onClick={handleClose} className="sm:hidden p-1 rounded-full">
            <LeftCircleOutlined className="font-bold text-xl text-red-900 hover:scale-105 hover:bg-slate-100 text-center" />
          </button>
       </div> 
       <div className="flex flex-col gap-6">
        {links.map((link, key) => (
          <NavLink
            key={key}
            onClick={handleClose}
            className={({ isActive }) =>
              `${
                isActive
                  ? "before:absolute before:w-1 before:h-6 before:left-0 before:bg-dark-blue text-base font-semibold  text-dark-blue "
                  : "text-base text-left text-sideTextColor hover:scale-105  hover:font-semibold"
              } w-40 text-left`
            }
            to={link.to}
          >
            <MenuItem icon={<PieChartOutlined />}>{link.name}</MenuItem>
          </NavLink>
        ))}
      </div> 
    </Menu> */
  );
}

export default Sidebar;
