import { yupResolver } from "@hookform/resolvers/yup";
import { sendFcmNotificationApi } from "api/notification";
import { useNotificationContext } from "contexts/NotificationContext";
import { INotificationInput } from "models/notification";
import { useState } from "react";
import { useForm } from "react-hook-form";
import notificationValidator from "validator/notificationValidator";

export default function useNotification() {
  const { showError, showSuccess } = useNotificationContext();
  const { control, handleSubmit } = useForm<INotificationInput>({
    resolver: yupResolver(notificationValidator),
  });
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (input: INotificationInput) => {
    setLoading(true);
    const { error } = await sendFcmNotificationApi(input);
    if (error) showError(error.message);
    else showSuccess("Notification sent successfully");
    setLoading(false);
  };

  return {
    control,
    handleSubmit,
    onSubmit,
    loading,
  };
}
