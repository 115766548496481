import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ISignInInput } from "models/auth";
import signInValidator from "validator/signInValidator";
import { signInApi } from "api";
import { useNotificationContext } from "contexts/NotificationContext";
import { RouteName } from "routes";
import { useState } from "react";

export default function useSignIn() {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { showError, showSuccess } = useNotificationContext();
  const { control, handleSubmit } = useForm<ISignInInput>({
    resolver: yupResolver(signInValidator),
  });

  const onSubmit: SubmitHandler<ISignInInput> = async (input) => {
    setLoading(true);
    const { data, error } = await signInApi(input);
    if (data) {
      localStorage.setItem("token", data.token);
      showSuccess("You have successfully logged in");
      navigate(RouteName.DashBoard, { replace: true });
    } else {
      showError(error?.message ?? "Network error");
    }
    setLoading(false);
  };

  return { onSubmit, handleSubmit, control, loading };
}
