import { useForm } from "react-hook-form";
import SurveyInfo from "./components/SurveyInfo";

export default function CreateSurvey() {
  const { control, handleSubmit } = useForm();

  const onSubmit = (input: any) => {
    console.log(input);
  };

  return (
    <div className="w-full">
      <div className="w-full rounded-lg bg-white px-4 py-5 flex flex-col gap-y-4">
        <div className="w-full flex flex-col sm:flex-row sm:items-center justify-between gap-5">
          <h1 className="text-black text-2xl font-normal whitespace-nowrap">
            Create Survey
          </h1>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full flex flex-col gap-y-3"
        >
          <SurveyInfo control={control} />
          <div className="w-full flex flex-col gap-y-2">
            <h1 className="text-black text-2xl font-normal whitespace-nowrap">
              Options
            </h1>
            <div className="w-full"></div>
          </div>
        </form>
      </div>
    </div>
  );
}
