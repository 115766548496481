import * as yup from "yup";

const notificationValidator = yup
  .object({
    content: yup.string().required("Content is required"),
    title: yup.string().required("Title is required"),
    business_categories: yup.array(yup.string().required()).nullable(),
    business_states: yup.array(yup.string().required()).nullable(),
    business_funded_year: yup.array(yup.string().required()).nullable(),
  })
  .required();

export default notificationValidator;
