import * as yup from "yup";

const smsValidator = yup
  .object({
    business_categories: yup.array(yup.string().required()).nullable(),
    business_states: yup.array(yup.string().required()).nullable(),
    business_funded_year: yup.array(yup.string().required()).nullable(),
    content: yup.string().required("Content is required"),
  })
  .required();

export default smsValidator;
