import { Controller, Control } from "react-hook-form";

interface IProps {
  control: Control<any>;
  name: string;
  placeholder?: string;
  label: string;
}

export default function LabeledTextInput({
  control,
  name,
  placeholder,
  label,
}: IProps) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={""}
      rules={{ required: true }}
      render={({ field, fieldState: { error } }) => (
        <div className="w-full flex flex-col gap-y-1">
          <label
            htmlFor={name}
            className="capitalize text-textColor text-sm text-left"
          >
            {label}
          </label>
          <input
            {...field}
            type="text"
            className="w-full outline-none bg-white px-4 py-2.5 text-sm rounded-lg border border-[rgb(229, 231, 235)]"
            placeholder={placeholder}
          />
          <p
            className="text-sm font-normal text-left mt-1 capitalize"
            style={{ color: "red" }}
          >
            {error?.message}
          </p>
        </div>
      )}
    />
  );
}
