import { authorizeGetRequest } from "api";
import { ISurveyResponse } from "models/survey";

export const fetchSurveysApi = (
  page: number,
  perPage: number,
  search: string
) => {
  return authorizeGetRequest<ISurveyResponse>(
    `surveys?page=${page}&per_page=${perPage}&search=${search}`
  );
};
