import { postRequest } from "api";
import {
  IForgetPassword,
  IResetPassword,
  ISignInInput,
  IForgetResponse,
  ISignInResponse,
} from "models/auth";

export function forgetPasswordApi(data: IForgetPassword) {
  return postRequest<IForgetResponse>(`auth/admin/forgot-password`, data);
}

export function signInApi(data: ISignInInput) {
  return postRequest<ISignInResponse>(`auth/admin/login`, data);
}

export function resetPasswordApi(data: IResetPassword) {
  return postRequest(`auth/admin/reset-password`, data);
}
