import { Button } from "antd";
import { businessCategories } from "../helpers/businessCategories";
import LabeledMultipleSelectInput from "components/Form/LabeledMultipleSelectInput";
import { businessStates } from "../helpers/businessStates";
import { businessFoundedYears } from "../helpers/businessFoundedYears";
import LabeledTextareaInput from "components/Form/LabeledTextareaInput";
import useSms from "hooks/useSms";

function Sms() {
  const { control, handleSubmit, loading, onSubmit } = useSms();

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full h-auto bg-white rounded-lg px-8 py-5 flex flex-col gap-y-4"
    >
      <LabeledMultipleSelectInput
        label="Business Category"
        control={control}
        name="business_categories"
        placeholder="Please select business category"
        defaultValue={[]}
        options={businessCategories.map((category) => ({
          label: category,
          value: category,
        }))}
      />
      <LabeledMultipleSelectInput
        label="Business State"
        control={control}
        name="business_states"
        placeholder="Please select business state"
        defaultValue={[]}
        options={businessStates.map((state) => ({
          label: state,
          value: state,
        }))}
      />
      <LabeledMultipleSelectInput
        label="Business Funded Year"
        control={control}
        name="business_funded_year"
        placeholder="Please select business founded year"
        defaultValue={[]}
        options={businessFoundedYears().map((year) => ({
          label: year,
          value: year,
        }))}
      />
      <LabeledTextareaInput
        control={control}
        name="content"
        label="Content"
        placeholder="please write your content"
      />
      <Button
        loading={loading}
        htmlType="submit"
        type="primary"
        className="w-full h-auto py-3 px-5 outline-none border disabled:opacity-50 bg-btnColor rounded-xl capitalize text-white font-medium text-center"
      >
        send Sms
      </Button>
    </form>
  );
}

export default Sms;
