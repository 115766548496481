import { Tabs } from "antd";
import AppConfig from "./components/AppConfig";
import Notification from "./components/Notification";
import Sms from "./components/Sms";

export default function Setting() {
  return (
    <div className="flex flex-col lg:flex-row gap-x-12 gap-y-12">
      <Tabs
        tabBarStyle={{ width: "100%" }}
        className="w-full"
        defaultActiveKey="1"
        items={[
          {
            key: "1",
            label: (
              <h1 className="text-2xl font-bold text-center capitalize">
                send Notification
              </h1>
            ),
            children: <Notification />,
            style: { width: "100%" },
          },
          {
            key: "2",
            label: (
              <h1 className="text-2xl font-bold text-center capitalize">
                send Sms
              </h1>
            ),
            children: <Sms />,
          },
        ]}
        // onChange={onChange}
        indicator={{ size: (origin) => origin - 20, align: "center" }}
      />
      <AppConfig />
    </div>
  );
}
