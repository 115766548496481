import { Select } from "antd";
import { Controller } from "react-hook-form";

interface IProps {
  placeholder?: string;
  control: any;
  name: string;
  defaultValue?: string[];
  options: { label: string; value: string }[];
  label: string;
}

export default function LabeledMultipleSelectInput({
  control,
  name,
  defaultValue,
  options,
  placeholder,
  label,
}: IProps) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ?? ""}
      render={({ field, fieldState: { error } }) => (
        <div className="w-full flex flex-col gap-y-1">
          <label
            htmlFor="title"
            className="capitalize text-textColor text-sm text-left"
          >
            {label}
          </label>
          <Select
            placeholder={placeholder}
            mode="multiple"
            size={"large"}
            className="w-full text-sm outline-none bg-white border-none"
            {...field}
            options={options}
          />
          <p
            className="text-sm font-normal text-left mt-1 capitalize"
            style={{ color: "red" }}
          >
            {error?.message}
          </p>
        </div>
      )}
    />
    // <p>{errors.status?.message || errors.status?.label.message}</p>
  );
}
